<template>
    <div class="root_header">
        <div class="info_user">
            <div class="img_name">
                <router-link to='/' title="">
                    <img src="../assets/images/logo.png" alt="">
                </router-link>
            </div>
            <div class="setting_user">
                <img src="../assets/images/menu.png" alt="" class="hambuger" @click="isOpen = !isOpen">
                <div class="menuRight_header" v-show="isOpen">
                    <ul>
                        <li @click="clickSearchHd"><img src="../assets/images/search.png" alt=""> Tìm kiếm</li>
                        <li @click="clickChatHd"><img src="../assets/images/chat.png" alt=""> Nhắn tin</li>
                        <li @click="clickListHd"><img src="../assets/images/list.png" alt=""> Danh sách like</li>
                        <li @click="logout"><img src="../assets/images/dx.png" class="logoutImg" alt=""> Đăng xuất</li>
                        <li @click="showMConfig"><img src="../assets/images/dl.png" class="" alt=""> Xóa tài khoản</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="rootMd_confirm">
            <div class="overlay" v-if="modals" @click="modals = false"></div>
            <div class="modal modal_confirm" v-if="modals">
                <div class="modalconfirm">
                    <img src="../assets/images/wt.png" alt="">
                    <h3>Xác nhận xóa tài khoản</h3>
                    <div class="btnConfig">
                        <button @click="deleteUser">Xóa</button>
                        <button @click="modals = false">Hủy</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Header_Dating',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            img: require('@/assets/images/pic.png'),
            fullname: '',
            isOpen: false,
            modals: false,
        }
    },
    components: {
        
    },
    computed: {
        
    },
    created () {
        this.getListInfo()
    },
    methods: {
        clickSearchHd() {
            this.$router.push({
                name: "Home_Dating",
                params: { message: 1 }
            });
        },
        clickChatHd() {
            this.$router.push({
                name: "Home_Dating",
                params: { message: 2 }
            });
        },
        clickListHd() {
            this.$router.push({
                name: "Home_Dating",
                params: { message: 3 }
            });
        },
        getListInfo () {
            this.axios.get(this.$api_info, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.img = response.data.data.avatar
                this.fullname = response.data.data.fullname
            }).catch(e => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000);
                }
            })
        },
        logout () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.$api_logout,
                {

                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                localStorage.clear();
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000);
            })
        },
        shortText (txt) {
            return txt.length > 8 ? txt.slice(0, 8) + "..." : txt;
        },
        showMConfig () {
            this.modals = true
            this.isOpen = false
        },
        deleteUser () {
            $('.loading_show').show().fadeIn(10)
            this.modals = false
            this.axios.post(this.$api_deleteUser, 
                {
                    
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 500);
                }, 2000);
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.error,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        }
    }
}
</script>
<style>
</style>