<template>
    <div lass="wrapper_root_home">
        <div class="root_header">
            <div class="info_user">
                <div class="img_name active">
                    <router-link to='/profile' title="" @click="chat == 0">
                        <img :src="img" alt="">
                        <span>{{ shortText(fullname) }}</span>
                    </router-link>
                </div>
                <div class="setting_user">
                    <img src="../assets/images/menu.png" alt="" class="hambuger" @click="isOpen = !isOpen">
                    <div class="menuRight_header" v-show="isOpen">
                        <ul>
                            <li :class="{active: list == 1}" @click="clickSearch"><img src="../assets/images/search.png" alt=""> Tìm kiếm</li>
                            <li :class="{active: list == 3}" @click="clickChat"><img src="../assets/images/chat.png" alt=""> Nhắn tin</li>
                            <li :class="{active: list == 2}" @click="clickList"><img src="../assets/images/list.png" alt=""> Danh sách like</li>
                            <li @click="logout"><img src="../assets/images/dx.png" class="logoutImg" alt=""> Đăng xuất</li>
                            <li @click="showMConfig"><img src="../assets/images/dl.png" class="" alt=""> Xóa tài khoản</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="content_wpHome" :class="classMn">
                <img src="../assets/images/nope.png" class="imagcloseMn" alt="" @click="hideMn">
                <div class="listLike_userTab" v-if="list == 1">
                    <div class="cat_list">
                        <ul>
                            <li @click="changeTab1('tab1')" :class="{active: tabSelected1 == 'tab1'}">Danh mục</li>
                            <li @click="changeTab1('tab2')" :class="{active: tabSelected1 == 'tab2'}">Tìm kiếm</li>
                        </ul>
                    </div>
                    <div class="content_tab">
                        <div class="wpCat_search" v-if="tabSelected1 == 'tab1'">
                            <div class="wp_tiemsearch" v-for="(search, index) in listSearch" :key="index">
                                <h3>{{ search.name }}</h3>
                                <p>{{ search.title }}</p>
                                <div class="row wpItemSearch">
                                    <div class="col-md-6 col-sm-6 col-xs-6 itemSearch" v-for="(search1, index1) in search.child" :key="index1">
                                        <div class="img_nameSearch" @click="getListSearchUser(search1.id, $event)" ref="nameSearchIt" :data_name="search1.name">
                                            <img :src="search1.image" alt="" v-if="search1.image != null">
                                            <img src="../assets/images/sr.webp" alt="" v-else>
                                            <p>{{ search1.name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wpCat_search" v-if="tabSelected1 == 'tab2'">
                            <form action="" method="" @submit="searchInfoList">
                                <div class="wp_inputSearch">
                                    <label>Họ tên</label>
                                    <input type="text" v-model="nameUser" placeholder="Họ và tên">
                                </div>
                                <div class="wp_inputSearch">
                                    <label>Tỉnh thành</label>
                                    <v-select v-model="province" :options="listProvince" label="name" :reduce="item => item.id" placeholder="Chọn tỉnh thành..." clearable ></v-select>
                                </div>
                                <div class="wp_inputSearch">
                                    <label>Giới tính</label>
                                    <select v-model="sex">
                                        <option value="">Chọn giới tính</option>
                                        <option value="1">Nam</option>
                                        <option value="0">Nữ</option>
                                    </select>
                                </div>
                                <div class="wp_inputSearch">
                                    <label>Tuổi</label>
                                    <div class="row">
                                        <div class=" col-md-6 col-sm-6 col-xs-6 wp_inputage">
                                            <div class="inputage">
                                                <v-select v-model="yearStart" :options="ageStartArr" placeholder="Tuổi bắt đầu..." clearable ></v-select>
                                            </div>
                                        </div>
                                        <div class=" col-md-6 col-sm-6 col-xs-6 wp_inputage">
                                            <div class="inputage">
                                                <v-select v-model="yearEnd" :options="ageEndArr" placeholder="Tuổi kết thúc..." clearable ></v-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wp_inputSearch btnsearch">
                                    <button type="submit">Tìm kiếm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="listLike_userTab" v-if="list == 2">
                    <div class="cat_list">
                        <ul>
                            <li @click="changeTab(1)" :class="{active: tabSelected == 1}">Đã thích</li>
                            <li @click="changeTab(0)" :class="{active: tabSelected == 0}">Được thích</li>
                        </ul>
                    </div>
                    <div class="content_tab">
                        <div class="tabLikeUser" v-if="tabSelected == 1">
                            <div class="itemLikeUser" v-for="(item, index) in listData" :key="index">
                                <div class="imgLikeUser">
                                    <img :src="item.avatar" alt="">
                                </div>
                                <div class="fullnameLikeUser likeflex">
                                    <h3>
                                        {{ item.fullname }} 
                                        <img src="../assets/images/like.png" alt="" v-if="item.status == 1">
                                        <img src="../assets/images/super-like.png" alt="" v-else>
                                    </h3>
                                    <div class="unlikeUs">
                                        <img src="../assets/images/unlk.png" alt="" @click="unLike(item.id_row)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tabLikeUser" v-if="tabSelected == 0">
                            <div class="itemLikeUser" v-for="(item, index) in listData" :key="index">
                                <div class="imgLikeUser">
                                    <img :src="item.avatar" alt="">
                                </div>
                                <div class="fullnameLikeUser">
                                    <h3>
                                        {{ item.fullname }} 
                                        <img src="../assets/images/like.png" alt="" v-if="item.status == 1">
                                        <img src="../assets/images/super-like.png" alt="" v-else>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="listLike_userTab" v-if="list == 3">
                    <div class="content_tab">
                        <div class="tabLikeUser">
                            <div class="itemUserChat" v-for="(itemChat, indexChat) in listUserChat" :key="indexChat" @click="chatMessage(indexChat)">
                                <div class="imgLikeUser">
                                    <img :src="itemChat.avatar" alt="">
                                </div>
                                <div class="fullnameLikeUser">
                                    <h3>
                                        {{ itemChat.fullname }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="root_wrapper" :class="classTdheight">
            <p class="titleSearch" v-if="nameSr != ''"><img src="../assets/images/nope.png" alt="" @click="getListUserDel"> {{ nameSr }}</p>
            <p class="titleSearch" v-if="sh == 1 && queue.length <= 0"><img src="../assets/images/nope.png" alt="" @click="getListUserDel"> Dữ liệu đang cập nhật, vui lòng quay lại sau!</p>
            <div class="rootTd_home">
                <Tinder class="tinder_wrapper" v-if="isTinderVisible && queue.length > 0" :key="tinderKey" ref="tinder" key-name="id" :sync="true" :queue.sync="queue" :offset-y="0" @submit="onSubmit">
                    <template slot-scope="scope">
                        <div class="pic" :style="{ 'background-image': `url(${scope.data.id})` }">
                            <div class="info_user_pic">
                                <p class="p_action">Có hoạt động gần đây</p>
                                <h2 class="name_pic">{{ scope.data.fullname }} <span @click="showModal(scope.data.id_us)"><img src="../assets/images/if.png" alt=""></span></h2>
                                <p class="add_pic" v-if="scope.data.address != null"><img src="../assets/images/add.svg" alt=""> Sống tại {{ scope.data.address }}</p>
                                <p class="location_pic"><img src="../assets/images/location.svg" alt=""> Cách xa {{ currentRandom }}km</p>
                            </div>
                        </div>
                    </template>
                    <span class="rewind-pointer" slot="rewind">Quay lại</span>
                    <span class="nope-pointer" slot="nope">Không</span>
                    <span class="super-pointer" slot="super">Siêu Thích</span>
                    <span class="like-pointer" slot="like">Thích</span>
                </Tinder>
                <div id="modal-example" class="root-modal-info">
                    <div class="overlay" v-if="modal" @click="modal = false"></div>
                    <div class="modal modal_info" v-if="modal">
                        <div class="wp-modal-info">
                            <div class="modalpp">
                                <div class="wp_slider_info">
                                    <Carousel ref="carousel" :perPage="1" :navigationEnabled="true" :paginationEnabled="true" :loop="false" :autoplay="false"  :navigateTo.sync="currentSlide">
                                        <Slide v-for="(albumDt, index1) in itemCurrent.album" :key="index1">
                                            <img :src="albumDt.image" alt="Slide 1" class="img_slider_album">
                                        </Slide>
                                    </Carousel>
                                    <button class="close-modal" @click="hideModal"><img src="../assets/images/close.png" alt=""></button>
                                </div>
                                <div class="info_user_idol">
                                    <div class="info_detail">
                                        <div class="pd_lr_info">
                                            <h2 class="name_detail">{{ itemCurrent.fullname }}</h2>
                                            <p class="detail_us" v-if="itemCurrent.height != null"><img src="../assets/images/cm.svg" alt=""> {{ itemCurrent.height }}</p>
                                            <p class="detail_us" v-if="itemCurrent.address != null"><img src="../assets/images/add.svg" alt=""> Sống tại {{ itemCurrent.address }}</p>
                                            <p class="detail_us"><img src="../assets/images/location.svg" alt=""> Cách xa {{ currentRandom }}km</p>
                                        </div>
                                    </div>
                                    <RecursiveHome v-for="(info, index) in itemCurrent.declare_info" :key="index" :data="info" />
                                    <div class="info_detail" v-if="itemCurrent.hobby_list.length > 0">
                                        <div class="pd_lr_info" style="padding-top:0;">
                                            <h3 class="pc_detail">Sở thích</h3>
                                            <div class="wp_if_usder">
                                                <ul>
                                                    <li v-for="(hobby, index4) in itemCurrent.hobby_list" :key="index4">{{ hobby.name }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns" v-if="show == 1 && queue.length > 0">
                <img src="../assets/images/rewind.png" @click="decide('rewind')">
                <img src="../assets/images/nope.png" @click="decide('nope')">
                <img src="../assets/images/super-like.png" @click="decide('super')">
                <img src="../assets/images/like.png" @click="decide('like')">
            </div>
            <div class="btns_arrow" v-if="queue.length > 0">
                <div class="wp_btn_showhide_arrow">
                    <span class="hidebtn_arrow" v-if="checkshowArrow == 1" @click="hideArrow">Ẩn</span>
                    <span class="showbtn_arrow" v-else @click="showArrow">Hiển thị</span>
                </div>
                <div class="btn_arrow_detail" v-if="checkshowArrow == 1">
                    <span><img src="../assets/images/arrowleft.png" alt=""> Không</span>
                    <span><img src="../assets/images/arrowright.png" alt=""> Thích</span>
                    <span><img src="../assets/images/arrowup.png" alt=""> Mở thông tin</span>
                    <span><img src="../assets/images/arrowdown.png" alt=""> Đóng thông tin</span>
                    <span><img src="../assets/images/arrowenter.png" alt=""> Siêu thích</span>
                    <span><img src="../assets/images/space.png" alt=""> Ảnh tiếp theo</span>
                </div>
            </div>
        </div>
        <div class="modalchat_message" v-if="chat == 1">
            <img src="../assets/images/nope.png" alt="" @click="closeChat">
            <div class="imgNameUsChat">
                <img :src="userChat.avatar" alt=""> {{ userChat.fullname }}
            </div>
            <div class="wp_item_modalchat">
                <div class="wp_chat_tofind">
                    <div class="list_chat_message">
                        <div class="list_chat_detail_mes" @scroll="scrollDataDetailMes" ref="scrollMess">
                            <div class="item_chat_mes" :id="'itemMes'+item.id" v-for="(item, index) in listDetailMes" :key="index" :ref="'itemMes'+item.id">
                                <div :class="'leftidol_chat message_' + item.id" v-if="iduser != item.user_from">
                                    <p v-if="isSameUsers(item, listDetailMes[index + 1])" class="userChat">{{ item.fullname }}</p>
                                    <p v-else class="userChat" style="opacity: 0;">{{ item.fullname }}</p>
                                    <div class="left_text_mes">
                                        <div class="avartaId" v-if="isSameUsers(item, listDetailMes[index + 1])">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="avartaId" v-else><img :src="item.avatar" alt="" style="opacity: 0;"></div>
                                        <div class="textMes">
                                            <p v-if="item.image != null">
                                                <Fancybox>
                                                    <a data-fancybox="gallery" :href="item.image">
                                                        <img :src="item.image" alt="" class="imgchat_sh">
                                                    </a>
                                                </Fancybox>
                                            </p>
                                            <p v-else>{{ item.content }}</p>
                                            <span class="ngaytao_chat date_ngaytao" v-if="isSameUser(item, listDetailMes[index - 1])">{{ formatTime(item.time) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div :class="'right_user_chat message_' + item.id" v-else>
                                    <p v-if="isSameUsers(item, listDetailMes[index + 1])" class="userChat">{{ item.fullname }}</p>
                                    <p v-else class="userChat" style="opacity: 0;">{{ item.fullname }}</p>
                                    <div class="right_text_mes">
                                        <div class="textMes">
                                            <p v-if="item.image != null">
                                                <Fancybox>
                                                    <a data-fancybox="gallery" :href="item.image">
                                                        <img :src="item.image" alt="" class="imgchat_sh">
                                                    </a>
                                                </Fancybox>
                                            </p>
                                            <p v-else>{{ item.content }}</p>
                                            <span class="ngaytao_chat date_ngaytao" v-if="isSameUser(item, listDetailMes[index - 1])">{{ formatTime(item.time) }}</span>
                                        </div>
                                        <div class="avartaId" v-if="isSameUsers(item, listDetailMes[index + 1])">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="avartaId" v-else><img :src="item.avatar" alt="" style="opacity: 0;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form_chat_mes">
                        <form action="" method="post" @submit="sendChatMes">
                            <div class="wp_img_text_mes_chat">
                                <div class="picchat_div">
                                    <input type="file" id="imagechat" class="imagechat" accept="image/*" @change="submitImgMes($event)" />
                                    <label class="lbimagechat" for="imagechat">
                                        <img src="../assets/images/imgs.png" alt="">
                                    </label>
                                </div>
                                <div class="wp_inputchat_mes">
                                    <input type="text" id="textmeschat" v-model="content" class="inputchat_mes" placeholder="Viết tin nhắn...">
                                </div>
                                <div class="btn_sb_mes">
                                    <button type="submit" id="submitchatMes"></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="rootMd_confirm">
            <div class="overlay" v-if="modals" @click="modals = false"></div>
            <div class="modal modal_confirm" v-if="modals">
                <div class="modalconfirm">
                    <img src="../assets/images/wt.png" alt="">
                    <h3>Xác nhận xóa tài khoản</h3>
                    <div class="btnConfig">
                        <button @click="deleteUser">Xóa</button>
                        <button @click="modals = false">Hủy</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Tinder from "vue-tinder";
// import source from "@/bing";
import $ from 'jquery'
import moment from 'moment';
import Fancybox from './Fancybox.vue'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { Carousel, Slide } from 'vue-carousel';
import RecursiveHome from "@/components/RecursiveHome.vue";

export default {
    name: 'Home_Dating',
    components: { 
        Tinder,
        Carousel,
        Slide,
        Fancybox,
        vSelect,
        RecursiveHome
    },
    data () {
        return {
            token: window.localStorage.getItem('token'),
            img: require('@/assets/images/pic.png'),
            fullname: '',
            queue: [],
            offset: 0,
            history: [],
            modal: false,
            modals: false,
            show: 1,
            checkshowArrow: 1,
            currentSlide: 0,
            totalSlides: 5,
            source: [],
            orther: [],
            eorther: [],
            action: '',
            tabSelected: 1,
            tabSelected1: 'tab1',
            listData: [],
            type: 1,
            list: 1,
            currentRandom: this.getRandomNumber(),
            listSearch: [],
            nameSr: '',
            listUserChat: [],
            sex: '',
            yearStart: '',
            yearEnd: '',
            tinderKey: Date.now(),
            isTinderVisible: true,
            isOpen: false,
            userChat: [],
            chat: 0,

            listDetailMes: [],
            idMesEnd: '',
            idUser_to: '',
            idUsertd: '',
            content: '',
            other: [],
            listProvince: '',
            province: '',
            nameUser: '',
            ageStartArr: Array.from({ length: 18 }, (_, i) => i + 18),
            ageEndArr: Array.from({ length: 26 }, (_, i) => i + 35),
            sh: 0,
            classMn: 'removeActive',
            classTdheight: '',
            itemCurrent: []
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keydown', this.handleKeySpace);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keydown', this.handleKeySpace);
    },
    created() {
        this.mock();
        this.getListUser()
        this.getListInfo()
        this.getListLkAndUlk()
        this.getListSearch()
        this.getListUserChat()
        this.getlistProvince()
        if(this.$route.params.message == 1){
            this.list = 1
            this.classMn = ''
        }else if(this.$route.params.message == 2){
            this.list = 3
            this.classMn = ''
        }else if(this.$route.params.message == 3){
            this.list = 2
            this.classMn = ''
        }else{
            this.list = 1
            this.classMn = 'removeActive'
        }
    },
    methods: {
        mock(count, append = true) {
            this.axios.get(this.$api_listUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token,
                },
            }).then((response) => {
                this.source = response.data.data.list_user;
                count = this.source.length
                const newItems = this.source.slice(0, count).map(user => ({
                    id_us: user.id,
                    id: user.avatar,
                    fullname: user.fullname,
                    height: user.height,
                    address: user.address,
                    album: user.album,
                    hobby_list: user.hobby_list,
                    declare_info: user.declare_info,
                }));
                if (!append) {
                    this.queue = [];
                    this.tinderKey = Date.now();
                }
                this.$nextTick(() => {
                    this.queue = [...newItems];
                });
            })
        },
        onSubmit({ item }) {
            this.history.push(item);
            this.currentRandom = this.getRandomNumber();
            this.idUsertd = item.id_us
        },
        decide(choice) {
            this.$nextTick(() => {
                if (choice === "rewind") {
                    if (this.history.length) {
                        this.$refs.tinder.rewind([this.history.pop()]);
                    }
                }else if(choice === "nope"){
                    this.$refs.tinder.decide(choice);
                } else {
                    // 0:không thích  1:thích 2:siêu thích
                    if(choice === "like"){
                        this.action = 1
                    }else{
                        this.action = 2
                    }
                    this.$refs.tinder.decide(choice);
                    this.axios.post(this.$api_likeUser,
                    {
                        user_id_like: this.idUsertd,
                        like: this.action
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }).then((response) => {
                        this.orther = response;
                    }).catch(e => {
                        this.eorther = e
                        localStorage.clear();
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1000);
                    })
                }
            });
        },
        handleKeyDown(event) {
            if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
                return;
            }
            if (event.key === "ArrowLeft") {
                this.swipeLeft();
            } else if (event.key === "ArrowRight") {
                this.swipeRight();
            } else if (event.key === "ArrowUp") {
                this.swipeUp();
            } else if (event.key === "ArrowDown") {
                this.swipeDown();
            } else if (event.key === "Enter") {
                this.swipeEnter();
            }
        },
        swipeLeft() {
            this.decide('nope')
            this.modal = false
            this.show = 1
        },
        swipeRight() {
            this.decide('like')
            this.modal = false
            this.show = 1
        },
        swipeUp() {
            this.modal = true
            this.show = 0
            this.currentSlide = 0
        },
        swipeDown() {
            this.modal = false
            this.show = 1
        },
        swipeEnter() {
            this.decide('super')
            this.modal = false
            this.show = 1
        },
        showModal (id) {
            this.modal = true,
            this.show = 0
            this.currentSlide = 0
            this.itemCurrent = this.queue.find(q => q.id_us == id);
        },
        hideModal () {
            this.modal = false,
            this.show = 1
        },
        hideArrow () {
            this.checkshowArrow = 0
        },
        showArrow () {
            this.checkshowArrow = 1
        },
        handleKeySpace (event) {
            if (event.key === " ") {
                this.currentSlide = (this.currentSlide + 1) % this.totalSlides;
            }
        },
        showAlert() {
            this.$swal({
                // icon: 'success error',
                position: 'top-end',
                icon: 'error',
                title: 'This is an alert from SweetAlert2',
                showConfirmButton: false,
                timer: 3000
            });
        },
        getListUser () {
            // this.axios.get(this.$api_listUser, {
            //     headers: {
            //         Authorization: 'Bearer ' + this.token
            //     }
            // }).then((response) => {
            //     this.listUser = response.data.data.list_user
            // })
            // .catch(e => {
            //     this.eorther = e
            //     localStorage.clear();
            //     setTimeout(() => {
            //         window.location.href = '/'
            //     }, 1000);
            // })
        },
        getListInfo () {
            this.axios.get(this.$api_info, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.iduser = response.data.data.id
                this.img = response.data.data.avatar
                this.fullname = response.data.data.fullname
            }).catch(e => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000);
                }
            })
        },
        logout () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.$api_logout,
                {

                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                localStorage.clear();
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000);
            })
        },
        shortText (txt) {
            return txt.length > 8 ? txt.slice(0, 8) + "..." : txt;
        },
        changeTab (tab) {
            this.tabSelected = tab
            this.type = tab
            this.getListLkAndUlk()
        },
        changeTab1 (tab) {
            this.tabSelected1 = tab
        },
        getListLkAndUlk () {
            this.axios.get(this.$api_listLike + '?type=' + this.type, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listData = response.data.data
            }).catch(e => {
                this.eorther = e
                localStorage.clear()
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000);
            })
        },
        clickList () {
            this.list = 2
            this.chat = 0
            this.isOpen = false
            this.province = this.sex = this.yearStart = this.yearEnd = ''
            this.classMn = ''
            if(this.queue.length <= 0){
                this.mock()
            }
            this.axios.get(this.$api_listLike + '?type=' + this.type, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listData = response.data.data
            })
        },
        clickSearch () {
            this.list = 1
            this.chat = 0
            this.isOpen = false
            this.province = this.sex = this.yearStart = this.yearEnd = ''
            this.classMn = ''
            // if(this.queue.length <= 0){
            //     this.mock()
            // }
        },
        clickChat () {
            this.list = 3
            // this.chat = 0
            this.isOpen = false
            this.province = this.sex = this.yearStart = this.yearEnd = ''
            this.classMn = ''
            if(this.queue.length <= 0){
                this.mock()
            }
        },
        getRandomNumber() {
            return Math.floor(Math.random() * 9) + 1; // Random từ 1 đến 9
        },
        getListSearch () {
            this.axios.get(this.$api_listSearch, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listSearch = response.data.data
            })
        },
        getListSearchUser (id, event) {
            $('.loading_show').show().fadeIn(10)
            this.modal = false,
            this.classMn = 'removeActive'
            this.classTdheight = 'activeHeight'
            this.$router.replace({
                name: "Home_Dating",
                params: { message: 0 }
            }).catch(err => {
                if (err.name !== "NavigationDuplicated") {
                    console.error(err);
                }
            });
            this.nameSr = event.currentTarget.getAttribute("data_name")
            this.axios.get(this.$api_listSearchUser + '?dating_criteria_id=' + id, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                $('#loading_show').css('display', 'none')
                this.queue = []
                this.isTinderVisible = false;
                this.source = response.data.data;
                const newItemsLt = [];
                for (let i = 0; i < this.source.length; i++) {
                    newItemsLt.push({
                        id_us: this.source[i]?.id,
                        id: this.source[i]?.avatar,
                        fullname: this.source[i]?.fullname,
                        height: this.source[i]?.height,
                        address: this.source[i]?.address,
                        album: this.source[i]?.album,
                        hobby_list: this.source[i]?.hobby_list,
                        declare_info: this.source[i]?.declare_info,
                    });
                }
                // this.$set(this, 'queue', this.queue);
                this.$nextTick(() => {
                    this.isTinderVisible = true;
                    this.tinderKey = Date.now()
                    this.queue = [...newItemsLt];
                });
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 2000);
                }
            })
        },
        getListUserDel () {
            $('.loading_show').show().fadeIn(10)
            this.province = this.sex = this.yearStart = this.yearEnd = ''
            this.nameSr = ''
            this.classTdheight = ''
            this.axios.get(this.$api_listUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                $('#loading_show').css('display', 'none')
                this.source = response.data.data.list_user;
                const newItems = [];
                this.offset = 0;
                for (let i = 0; i < this.source.length; i++) {
                    newItems.push({
                        id_us: this.source[i]?.id,
                        id: this.source[i]?.avatar,
                        fullname: this.source[i]?.fullname,
                        height: this.source[i]?.height,
                        address: this.source[i]?.address,
                        album: this.source[i]?.album,
                        hobby_list: this.source[i]?.hobby_list,
                        declare_info: this.source[i]?.declare_info,
                    });
                }
                this.queue = []
                this.$set(this, 'queue', []);
                this.tinderKey = Date.now();
                this.$nextTick(() => {
                    this.queue = [...newItems];
                });
                // this.queue = [...newItems];
                // this.$set(this, 'queue', this.queue);
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 2000);
                }
            })
        },
        getListUserChat () {
            this.axios.get(this.$api_listUserChat, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listUserChat = response.data.data;
            }).catch(e => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 2000);
                }
            })
        },
        searchInfoList (e) {
            e.preventDefault()
            this.sh = 1
            this.nameSr = ''
            this.classTdheight = ''
            this.classMn = 'removeActive'
            $('.loading_show').show().fadeIn(10)
            this.axios.get(this.$api_listUser + '?fullname=' + this.nameUser + '&province_id=' + this.province + '&sex=' + this.sex + '&year_old_start=' + this.yearStart + '&year_old_end=' + this.yearEnd, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                $('#loading_show').css('display', 'none')
                this.isTinderVisible = false;
                this.source = response.data.data.list_user;
                const newItems = [];
                for (let i = 0; i < this.source.length; i++) {
                    newItems.push({
                        id_us: this.source[i]?.id,
                        id: this.source[i]?.avatar,
                        fullname: this.source[i]?.fullname,
                        height: this.source[i]?.height,
                        address: this.source[i]?.address,
                        album: this.source[i]?.album,
                        hobby_list: this.source[i]?.hobby_list,
                        declare_info: this.source[i]?.declare_info,
                    });
                }
                this.$nextTick(() => {
                    this.isTinderVisible = true;
                    this.tinderKey = Date.now()
                    this.queue = [...newItems];
                });
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 2000);
                }
            })
        },
        getlistProvince () {
            this.axios.get(this.$api_province).then((response) => {
                this.listProvince = response.data.data
            })
        },
        chatMessage (index) {
            this.chat = 1
            this.userChat = this.listUserChat[index];
            this.classMn = 'removeActive'
            this.getDataDetailMes()
            this.$nextTick(() => {
                setTimeout(() => {
                    var hg = $('.list_chat_detail_mes').prop('scrollHeight');
                    $('.list_chat_detail_mes').animate({ scrollTop: hg+'px' }, 1000);
                }, 1500);
            });
        },
        closeChat () {
            this.chat = 0
        },
        getDataDetailMes () {
            this.axios.get(this.$api_listMessage + '?user_to=' + this.userChat.user_id,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                if (response.data.data.length > 0) {
                    this.listDetailMes = response.data.data
                    this.idMesEnd = this.listDetailMes[0].id
                }else{
                    this.listDetailMes = []
                }
            })
        },
        scrollDataDetailMes () {
            this.$nextTick(function() {
                var hg = this.$refs.scrollMess;
                let heightScroll = hg.scrollTop
                if (heightScroll == 0 ) {
                    this.axios.get(this.$api_listMessage + '?user_to=' + this.userChat.user_id + '&last_message_id=' + this.idMesEnd,
                        {
                            headers: {
                                Authorization: 'Bearer ' + this.token
                            }
                        }
                    ).then((response) => {
                        let dataAr = response.data.data
                        if(dataAr.length > 0) {
                            this.listDetailMes = dataAr.concat(this.listDetailMes)
                            this.idMesEnd = this.listDetailMes[0].id
                        }
                    }).catch(e => {
                        $('#loading_show').css('display', 'none')
                        if (e.response.status === 401) {
                            localStorage.clear();
                            setTimeout(function() {
                                window.location.href = '/'
                            }, 2000);
                        }
                    })
                }
            });
        },
        formatTime(time) {
            const now = moment();
            const givenTime = moment(time, "YYYY-MM-DD HH:mm:ss"); // Chuyển đổi chuỗi thành Moment object
            const diffMinutes = now.diff(givenTime, 'minutes');
            const diffHours = now.diff(givenTime, 'hours');
            // const diffDays = now.diff(givenTime, 'days');

            if (diffMinutes < 1) {
                return 'Vừa xong';
            } else if (diffMinutes < 60) {
                return `${diffMinutes} phút trước`;
            } else if (diffHours < 24) {
                return `${diffHours} giờ trước`;
            } else {
                return givenTime.format("HH:mm DD-MM-YYYY"); // Hiển thị dạng `09:58 12-03-2025`
            }
        },
        formatDate (date) {
            let datePart = date.split(' ')
            let dateMt = datePart[0].split('-')
            return dateMt[2] + '-' + dateMt[1] + '-' + dateMt[0];
        },
        isSameUser (currentMessage, diffMessage) {
            var d = ''
            if(typeof diffMessage === 'undefined'){
                d = this.formatDate(currentMessage.time)
            }else{
                d = this.formatDate(diffMessage.time)
            }
            var d1 = this.formatDate(currentMessage.time)
            if(diffMessage && d == d1 && diffMessage.user_from === currentMessage.user_from){
                setTimeout(() => {
                    $('.message_' + diffMessage.id + ' .ngaytao_chat').addClass('removeDiv')
                }, 100);
            }
            return true;
        },
        isSameUsers (currentMessage, diffMessage) {
            var d = ''
            if(typeof diffMessage === 'undefined'){
                d = this.formatDate(currentMessage.time)
            }else{
                d = this.formatDate(diffMessage.time)
            }
            var d1 = this.formatDate(currentMessage.time)
            if(diffMessage && d == d1 && diffMessage.user_from === currentMessage.user_from){
                setTimeout(() => {
                    $('.message_' + diffMessage.id + ' .avartaId').addClass('removeDivImg')
                    $('.message_' + diffMessage.id + ' .userChat').addClass('removeDivImg')
                }, 100);
            }
            return true;
        },
        sendChatMes (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            const form = new FormData();
            form.append('user_to', this.userChat.user_id);
            form.append('content', this.content);
            this.axios.post(this.$api_sendMessage, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other = response
                this.content = ''
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        submitImgMes (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            let url_image = e.target.files[0]
            // let url_image = document.getElementById('imagechat').files[0]
            const form = new FormData();
            form.append('user_to', this.userChat.user_id);
            form.append('image', url_image);
            this.axios.post(this.$api_sendMessage, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other = response
                $("#imagechat").val('');
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        hideMn () {
            this.classMn = 'removeActive'
        },
        showMConfig () {
            this.modals = true
            this.isOpen = false
        },
        deleteUser () {
            $('.loading_show').show().fadeIn(10)
            this.modals = false
            this.axios.post(this.$api_deleteUser, 
                {
                    
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 500);
                }, 2000);
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.error,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        unLike (id) {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.$api_unlikeUser,
                {
                   id: id
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
            }).then((response) => {
                $('#loading_show').css('display', 'none')
                const index = this.listData.findIndex(item => item.id_row == id);
                if (index !== -1) {
                    this.listData.splice(index, 1);
                }
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 2000);
                }
            })
        },
    }
}
</script>
<style scoped>
</style>