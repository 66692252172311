<template>
    <div class="info_detailIdol" v-if="data.type == 1">
        <div v-if="data.children && data.children.length > 0">
            <template>
                <h3 class="pc_detail" v-if="data.level === 1">{{ data.name  }}</h3>
                <p class="nameinfo_p" v-else-if="data.level > 1">{{ data.name }}</p>
                <div v-for="(child, index) in data.children" :key="index" class="wp_if_usder">
                    <RecursiveHome :data="child" />
                </div>
            </template>
        </div>
        <!-- Nếu là cấp cuối cùng, hiển thị danh sách <ul><li> -->
        <ul v-else>
            <li>{{ data.name }}</li>
        </ul>
    </div>
    <div class="info_detailIdol" v-else-if="data.type != 1 && data.declare_name != ''">
        <div>
            <template>
                <h3 class="pc_detail">{{ data.name }}</h3>
                <ul>
                    <li>{{ data.declare_name }}</li>
                </ul>
            </template>
        </div>
    </div>
</template>
<script>
    export default {
        name: "RecursiveHome",
        props: {
            data: Object
        }
    };
</script>
  