<template>
    <div class="root_dating">
        <div class="wp_root_nologin" v-if="token == null">
            <div class="login_screen">
                <Login_Dating @showRegister="showDk" v-if="check == 0"/>
                <Register_Dating @showLogin="showDn" v-else/>
            </div>
        </div>
        <div class="wp_root_login" v-else>
            <Header_Dating v-if="this.$route.name != 'Home_Dating'" />
            <div id="app">
                <router-view :key ='$route.params'/>
            </div>
        </div>
        <div id="loading_show" class="loading_show" style="position: fixed; left: 0; bottom: 0; top: 0%; right: 0%; text-align: center; background: rgba(0,0,0,.5); z-index: 999999999;">
            <div class="windows8"> 
                <div class="wBall" id="wBall_1"> 
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_2">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_3">
                    <div class="wInnerBall"></div>
                </div> 
                <div class="wBall" id="wBall_4">
                    <div class="wInnerBall"></div> 
                </div>
                <div class="wBall" id="wBall_5"> 
                    <div class="wInnerBall"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "./assets/css/style.css"
import Login_Dating from './components/Login.vue'
import Register_Dating from './components/Register.vue'
import Header_Dating from './components/Header.vue'

export default {
    name: 'App',
    data () {
        return {
            check: 0,
            token: window.localStorage.getItem('token'),
        }
    },
    components: {
        Login_Dating,
        Register_Dating,
        Header_Dating
    },
    created () {
        
    },
    methods: {
        showDk () {
            this.check = 1
        },
        showDn () {
            this.check = 0
        }
    }
}
</script>