import Vue from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import $ from 'jquery';
Vue.prototype.$ = $;

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

var domain = 'https://adm.tofind.fun/api/'

Vue.prototype.$api_location = 'https://geolocation-db.com/json/';

Vue.prototype.$api_linkweb = 'https://adm.tofind.fun/';
Vue.prototype.$api_login = domain + 'login';
Vue.prototype.$api_logout = domain + 'logout';
Vue.prototype.$api_register = domain + 'register_user';
Vue.prototype.$api_info = domain + 'get_info_user';
Vue.prototype.$api_edit = domain + 'edit_user';
Vue.prototype.$api_deleteImg = domain + 'delete_image';
Vue.prototype.$api_addEditImg = domain + 'post_image';
Vue.prototype.$api_listInfoChose = domain + 'get_declare';
Vue.prototype.$api_listUser = domain + 'get_list_user';
Vue.prototype.$api_listHobby = domain + 'get_hobby';
Vue.prototype.$api_likeUser = domain + 'like_image';
Vue.prototype.$api_listLike = domain + 'get_like_other';
Vue.prototype.$api_listSearch = domain + 'get_dating_category';
Vue.prototype.$api_listSearchUser = domain + 'get_user_category';
Vue.prototype.$api_listUserChat = domain + 'get_each_other';
Vue.prototype.$api_listMessage = domain + 'list_message';
Vue.prototype.$api_sendMessage = domain + 'send_messages';
Vue.prototype.$api_province = domain + 'province';
Vue.prototype.$api_deleteUser = domain + 'delete_user';
Vue.prototype.$api_unlikeUser = domain + 'un_like_image';

Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
