<template>
    <div class="rootRcsLt">
        <div :class="'level-' + item.level" v-if="item.type == 1">
            <h3 v-if="item.children.length > 0" class="menu_1">{{ item.name }}</h3>
            <ul v-if="item.children.length > 0" class="itemRadioCheck">
                <li v-for="(child, index) in item.children" :key="index" :class="['radio', { 'selected-radio': selectedValues[item.id] === child.id, 'last-level': !child.children || child.children.length === 0 }]">
                    <RecursiveList 
                        :item="child" 
                        :selectedValues="selectedValues" 
                        @update-selected="updateSelected" 
                    />
                </li>
            </ul>
            <h3 v-else-if="item.parent_id === 0" class="menu_1">{{ item.name }}</h3>
            <div v-else class="radio-buttons">
                <div class="button">
                    <div class="rt_wp_ip">
                        <input type="radio" :value="item.id" :name="'group-' + item.parent_id" v-model="localSelectedValues[item.parent_id]" @change="emitChange">
                        <span>{{ item.name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="level-1" v-else>
            <h3 class="menu_1">{{ item.name }}</h3>
            <input type="text" v-model="declare_nameIf[item.id]" :placeholder="item.name">
        </div>
    </div>
</template>
  
<script>
    export default {
        name: "RecursiveList",
        props: ["item", "selectedValues"],
        data() {
            return {
                localSelectedValues: { ...this.selectedValues },
                declare_nameIf: {}
            };
        },created() {
            // if (!this.declare_nameIf[this.item.id] && this.item.declare_name) {
            //     this.$set(this.declare_nameIf, this.item.id, this.item.declare_name);
            // }
            if (!(this.item.id in this.declare_nameIf)) {
                this.$set(this.declare_nameIf, this.item.id, this.item?.declare_name ?? '');
            }
        },
        methods: {
            emitChange() {
                this.$emit("update-selected", this.localSelectedValues);
            },
            updateSelected(newValues) {
                this.localSelectedValues = { ...newValues };
                this.$emit("update-selected", this.localSelectedValues);
            },
            getSelectedPairs(items = [this.item], result = []) {
                items.forEach(item => {
                    if (item.type == 1) {
                        // Nếu radio được chọn, lưu cặp (parent_id, id)
                        if (this.selectedValues[item.parent_id] === item.id) {
                            result.push({
                                parent_id: item.parent_id,
                                declare_id: item.id,
                                type: 1
                            });
                        }
                    }else{
                        result.push({
                            declare_id: item.id,
                            declare_name: this.declare_nameIf[item.id],
                            type: 0
                        });
                    }
                    // Gọi đệ quy nếu có children
                    if (item.children && item.children.length > 0) {
                        this.getSelectedPairs(item.children, result);
                    }
                });
                return result;
            }
        },
        watch: {
            selectedValues: {
                handler(newVal) {
                    this.localSelectedValues = { ...newVal };
                },
                deep: true
            }
        }
    };
</script>
  