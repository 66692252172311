<template>
    <div class="root_login">
        <div class="logo_login">
            <img src="../assets/images/logo.png" alt="">
        </div>
        <form class="frm_login" action="" method="post" id="frm_login" @submit="login">
            <div class="wp_input">
                <input type="text" v-model="username" placeholder="Tên đăng nhập">
                <img src="../assets/images/user.png" alt="" class="icon_input">
            </div>
            <div class="wp_input">
                <input type="password" v-model="password" placeholder="Mật khẩu">
                <img src="../assets/images/pw.png" alt="" class="icon_input">
            </div>
            <p class="text_register">Bạn chưa có tài khoản? <span @click="handleClick">Đăng ký</span></p>
            <div class="btn_login">
                <button type="submit">Đăng nhập</button>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Login_Dating',
    data () {
        return {
            username: '',
            password: '',
        }
    },
    components: {
        
    },
    created () {
    },
    methods: {
        handleClick () {
            this.$emit('showRegister')
        },
        login (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.$api_login, {
                username: this.username,
                password: this.password,
            }).then(res => {
                $('#loading_show').css('display', 'none')
                // console.log('000', res.data.data);
                
                window.localStorage.setItem('token', res.data.data.token)
                let today = new Date();
                let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                let timestamp = date + ' ' + time;
                window.localStorage.setItem('time_token', timestamp)
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.reload()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                console.log('111', e);
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
    }
}
</script>
<style>
</style>