<template>
    <div class="root_login">
        <div class="logo_login">
            <img src="../assets/images/logo.png" alt="">
        </div>
        <form class="frm_login" action="" method="post" id="frm_login" @submit="register">
            <div class="wp_input">
                <input type="text" v-model="fullname" placeholder="Họ và tên">
                <img src="../assets/images/user.png" alt="" class="icon_input">
            </div>
            <div class="wp_input">
                <input type="text" v-model="username" placeholder="Tên đăng nhập">
                <img src="../assets/images/user.png" alt="" class="icon_input">
            </div>
            <div class="wp_input">
                <input type="password" v-model="password" placeholder="Mật khẩu">
                <img src="../assets/images/pw.png" alt="" class="icon_input">
            </div>
            <div class="wp_input">
                <img src="../assets/images/sex.png" alt="" class="icon_input">
                <select v-model="sex">
                    <option value="">Chọn giới tính</option>
                    <option value="1">Nam</option>
                    <option value="0">Nữ</option>
                </select>
            </div>
            <div class="wp_input">
                <input type="text" v-model="phone" placeholder="Số điện thoại">
                <img src="../assets/images/call.png" alt="" class="icon_input">
            </div>
            <div class="wp_input">
                <input type="email" v-model="email" placeholder="Email">
                <img src="../assets/images/email.png" alt="" class="icon_input">
            </div>
            <div class="wp_input">
                <date-picker v-model="birthday" format="DD-MM-YYYY" placeholder="Chọn ngày" />
            </div>
            <div class="wp_input">
                <input type="text" v-model="address" placeholder="Địa chỉ">
                <img src="../assets/images/location.png" alt="" class="icon_input">
            </div>
            <div class="wp_input">
                <input type="text" v-model="height" placeholder="Chiều cao">
                <img src="../assets/images/height.png" alt="" class="icon_input">
            </div>
            <div class="wp_input_file">
                <div class="img_preview" v-if="imageUrl">
                    <img :src="imageUrl" alt="Preview"/>
                </div>
                <div class="file-input">
                    <input type="file" class="file_img" id="file_img" @change="onFileChange" accept="image/*">
                    <span class='button'>Ảnh đại diện</span>
                    <span class='label'>{{ nameImg }}</span>
                </div>
            </div>
            <div class="wp_input_file">
                <div class="img_previewAlbum" v-if="imageUrls.length">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-4 img-wrapper" v-for="(image, index) in imageUrls" :key="index">
                            <img :src="image" alt="Preview" />
                            <span class="delete_img" @click="removeImage(index)"><img src="../assets/images/delete.png" alt=""></span>
                        </div>
                    </div>
                </div>
                <div class="file-input">
                    <input type="file" class="file_img_album" id="file_img_album" @change="onFileChangeAlbum" accept="image/*" multiple />
                    <span class="button">Chọn album</span>
                    <span class="label">{{ imageUrls.length }} file được chọn</span>
                </div>
            </div>
            <p class="text_register text_login">Bạn đã có tài khoản? <span @click="handleClick">Đăng nhập</span></p>
            <div class="btn_login">
                <button type="submit">Đăng ký</button>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    name: 'Register_Dating',
    data () {
        return {
            fullname: '',
            username: '',
            password: '',
            sex: '',
            phone: '',
            email: '',
            address: '',
            height: '',
            avatar: '',
            imageUrl: null,
            nameImg: 'Chưa có hình ảnh',
            nameImgs: [],
            imageUrls: [],
            IPv4: '',
            city: '',
            country_code: '',
            country_name: '',
            latiude: '',
            longiude: '',
            birthday: ''
        }
    },
    components: {
        DatePicker
    },
    created () {
        this.getlocation()
    },
    methods: {
        getlocation () {
            this.axios.get(this.$api_location).then((response) => {
                this.IPv4 = response.data.IPv4
                this.city = response.data.city
                this.country_code = response.data.country_code
                this.country_name = response.data.country_name
                this.latiude = response.data.latitude
                this.longiude = response.data.longitude
            })
        },
        handleClick () {
            this.$emit('showLogin')
        },
        onFileChange(event) {
            this.nameImg = event.target.files[0].name
            const file = event.target.files[0];
            if (file && file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    this.imageUrl = e.target.result;
                };
            } else {
                this.imageUrl = null; // Xóa URL nếu không phải ảnh
            }
        },
        onFileChangeAlbum(event) {
            this.nameImgs = [];
            this.imageUrls = [];
            const files = event.target.files;
            if (files && files.length) {
                Array.from(files).forEach((file) => {
                if (file.type.startsWith("image/")) {
                    this.nameImgs.push(file.name); // Lưu tên ảnh
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                    this.imageUrls.push(e.target.result); // Lưu URL ảnh
                    };
                }
                });
            }
        },
        removeImage(index) {
            this.imageUrls.splice(index, 1);
            this.nameImgs.splice(index, 1);
        },
        register (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let file_img = document.getElementById('file_img').files[0]
            const form = new FormData();
            form.append('username', this.username);
            form.append('password', this.password);
            form.append('fullname', this.fullname);
            form.append('sex', this.sex);
            form.append('address', this.address);
            form.append('phone', this.phone);
            form.append('email', this.email);
            form.append('birthday', this.formatDate(this.birthday));
            form.append('height', this.height);
            form.append('avatar', file_img);
            form.append('IPv4', this.IPv4);
            form.append('city', this.city);
            form.append('country_name', this.country_name);
            form.append('latiude', this.latiude);
            form.append('longiude', this.longiude);
            // form.append('postal', this.postal);
            // form.append('state', this.state);
            let file_imgAb = document.getElementById('file_img_album').files
            if (file_imgAb && file_imgAb.length > 0) {
                for (let i = 0; i < file_imgAb.length; i++) {
                    form.append('album[]', file_imgAb[i]);
                }
            }
            this.axios.post(this.$api_register, 
                form
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                // console.log('111', e);
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        formatDate(date) {
            if (!date) return "";
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
    }
}
</script>
<style>
</style>