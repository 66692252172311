import { render, staticRenderFns } from "./RecursiveHome.vue?vue&type=template&id=c83db9ca"
import script from "./RecursiveHome.vue?vue&type=script&lang=js"
export * from "./RecursiveHome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports